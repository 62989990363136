import Icon from '@pretto/bricks/components/iconography/Icon'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import ContentHero from '@pretto/bricks/website/shared/components/ContentHero'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import * as S from './styles'

const ContactHeader = ({ onShowFront }) => (
  <Fragment>
    <Wrapper full>
      <S.Header>
        <ContentHero title="Contactez l’équipe Pretto" titleAlignment="center" />
      </S.Header>
    </Wrapper>

    <Wrapper>
      <S.Content>
        <S.HeaderImage alt="Contact" path="website/page/contact/contact-image.png" />

        <S.Information>
          <p>
            Vous pouvez nous contacter : <br />
            Du lundi au vendredi de 9h à 18h.
          </p>
          <a href="mailto:hello@pretto.fr" rel="nofollow" target="blank">
            <SubHeading size="large" type="h3">
              <S.Title>
                <Icon name="mail" />
                Par email
              </S.Title>
            </SubHeading>
            <S.Link>hello@pretto.fr</S.Link>
          </a>
          <SubHeading>
            <a href="tel:0176390641" rel="nofollow">
              <S.Title>
                <Icon name="phone" />
                01 76 39 06 41
              </S.Title>
            </a>
          </SubHeading>
        </S.Information>
      </S.Content>
    </Wrapper>
  </Fragment>
)

ContactHeader.propTypes = {
  onShowFront: PropTypes.func,
}

export default memo(ContactHeader)
